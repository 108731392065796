/* OFFCANVAS */
.btn-close {
    box-sizing: content-box;
    padding-left: 5px;
	padding-right: 5px;
    color: #000;
    background: transparent;
    border: 0;
    border-radius: .25rem;
    opacity: .5;
	font-size: 16px;
}
.float-end {
    float: right!important;
}
.navbar-fengshui{
	background-color: #f8f9fa !important;
}
body.offcanvas-active{
	overflow:hidden;
}

.offcanvas-header{ display:none; }

.screen-darken{
	height: 100%;
	width:0%;
	z-index: 30;
	position: fixed;
	top: 0;
	right: 0;
	opacity:0;
	visibility:hidden;
	background-color: rgba(34, 34, 34, 0.6);
	transition:opacity .2s linear, visibility 0.2s, width 2s ease-in;
}

.screen-darken.active{
	z-index:10; 
	transition:opacity .3s ease, width 0s;
    opacity:1;
    width:100%;
    visibility:visible;
}
.navbar-mobile{
	display: none;
}
.txt-logo-mobile{
	color: #000000 !important;
}
/* ============ mobile view ============ */
@media all and (max-width: 991px) {
	.navbar-mobile{
		display: block;
		overflow: hidden;
		background-color: #f8f9fa;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	
	.offcanvas-header{ display:block; }

	.mobile-offcanvas{
		visibility: hidden;
		transform:translateX(-100%);
	    border-radius:0; 
		display:block;
	    position: fixed;
	    top: 0; left:0;
	    height: 100%;
	    z-index: 1200;
	    width:80%;
	    overflow-y: scroll;
	    overflow-x: hidden;
	    transition: visibility .3s ease-in-out, transform .3s ease-in-out;
	}

	.mobile-offcanvas.show{
		visibility: visible;
    	transform: translateX(0);
	}
	.mobile-offcanvas .container, .mobile-offcanvas .container-fluid{
		display: block;
	}

}	


/**/

/* TABLAS */
.title-tablas{
	font-size: 20px;
}
.fila-tablas{
	background-color: #eeeeee;
	padding-top: 20px;
	padding-bottom: 20px;
}

.link-tablas{
	color: #000000;
	font-weight: bold;
}

html {
	scroll-behavior: smooth;
}
  
/* MAIN.CSS */
* {
	margin: 0px;
	padding: 0px;
	/*font-weight: 400;*/
}
a:link {
	text-decoration:none;
	color:inherit;
}
#content a:hover {
	text-decoration:underline
}

a:link img {
	border:none;
}
#content a:link {
	font-weight:700;
	color:inherit;
}
/* HTML Tag Redefinition */
html, body {
	height: 100%;
	font-size:small;
}
body {
	padding: 0px;
	margin: 0px;
	/* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/madera2.jpg); */
	background-position: center top;
}
img {
	border: 0px
}
a { cursor:pointer }

#estrellasvolantes .in{
	text-align: center;
}

.in-estrellasvolantes{
	padding: 49px !important;
}

.in-cuatropilares{
	padding: 28px !important;
}

input {
	color:inherit;
	background: none;
	border: none;
	padding:3px;
	background-color:rgba(255,255,255,.15);
	
	border-top-width: 1px;
	border-right-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-bottom-color: rgba(255,255,255,0.25);/*#EAD6BB;luz*/
	border-right-color: rgba(255,255,255,0.25);/*#EAD6BB;/*luz*/
	border-left-color: rgba(0,0,0,0.25);/*#8D6E34;/*sombra*/
	border-top-color: rgba(0,0,0,0.25);/*#8D6E34;/*sombra*/

	/*box-shadow: 0px 0px 5px rgba(0,0,0,0.5) inset;*/
	box-shadow: 
		0px 1px 1px rgba(255,255,255,.25),
		1px 0px 1px rgba(255,255,255,.25),
		-1px 0px 1px rgba(0,0,0,.5),
		0px -1px 1px rgba(0,0,0,.5),
      	inset 0 0 6px rgba(0,0,0,.35);			  
}
select {
	padding-left:6px;
	padding-right:0px;

	color:inherit;
	/* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/madera2.jpg); */
	background-position: center top;
	/* border:outset 1px burlywood; */
	/* box-shadow: 
	0px -1px 1px rgba(255,255,255,.5),
				-1px 0px 1px rgba(255,255,255,.5),
				0px 1px 2px rgba(0,0,0,.2),
				0px 1px 0px #8D6E34,
				0px 2px 0px #8a6b33,
				0px 3px 0px #795d2c,
				0px 4px 0px #6b5026,
				
				0px 5px 1px rgba(61,42,19,0.8),
				1px 6px 2px rgba(61,42,19,0.6),
				3px 8px 3px rgba(61,42,19,0.4),
				6px 11px 4px rgba(61,42,19,0.3)
				; */


}
.boton-calcular{
	background-color: #000;
	color: #fff;
	padding: 10px;
	border-radius: 8px;
}
.boton-calcular:hover{
	cursor: pointer;
	background-color: #fff;
	border-color: #000;
	color: #000;
}
table[cellspacing="6"] {
	margin-left:-6px;
	margin-right:-6px;
}
#content ul li {
	list-style-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/bullet_letterpress_dot.png);
}

/* ------------------- mis estilos: -------------------------- */
/* ----- FONTS --------- */
#nav_top, #banner_top {
	font-family: 'Montserrat', sans-serif;
	font-weight:400;
}
#nav_top {
	font-size: medium;	
}
h2, h3, h4, h5, h6 {
	font-weight:700;
}
#col_left h3 {
	text-transform: uppercase;
	margin-bottom: 3px;
}


/* ----- LAYOUT ------- */
#wrapper {
	text-align: center;
}

#main {
	text-align: left;
	margin-right: auto;
	margin-left: auto;
	width: 960px;
}

/* ::: header */
#header {
	height: 115px;
	width: 960px;
	background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/_header.png);
	text-indent: 0px;
}
#header_in {
	background-repeat: no-repeat;
	background-position: 25px 10px;
}
#header h1 {
	height:30px;
	letter-spacing: 1px;
	padding-top: 16px;
	padding-left: 75px;
	font-size: 23px;
	text-shadow:	-0px -1px 1px rgba(89, 101, 108, .5),
					-1px -0px 1px rgba(89, 101, 108, .5),
					-1px -1px 1px rgba(89, 101, 108, .5),
					-2px -2px 2px rgba(89, 101, 108, 0.25),
					0px 1px 1px rgba(255, 255, 255, .5),
					1px 0px 1px rgba(255, 255, 255, .5),
					1px 1px 1px rgba(255, 255, 255, 1),
					2px 2px 2px rgba(255, 255, 255, 0.5);/* #4a5256; */
}
#nav_top {
	margin-left: 12px;
	margin-top: 30px;
	text-transform:uppercase;
	font-size:12px;
	font-weight:400;
	letter-spacing:1px;
	text-shadow: -0px -1px 1px #59656C,  0px 1px 1px #fff;
}
#nav_top ul li{
	float: left;
	list-style-type: none;
	padding-right: 0px;
	border-left-width: thin;
	border-left-style: solid;
	padding-left: 0px;
	/* */
	border:none;
}
#nav_top li .divider {
	padding-left: 4px;
	padding-right: 4px;
}
#nav_top li.first{
	border:none;
}
#nav_top li.selected a, #nav_top li.nav-selected a, #nav_top li.nav-path-selected a{
	position:relative;
	background-color:#364768;
	color:#DFD8D3;
	text-shadow: 0px 1px 1px #59656C,  -0px -1px 1px #fff;
	-moz-box-shadow: 	-0px -1px 1px #59656C, 
						-1px -1px 2px #59656C, 
						0px 1px 1px #fff, 
						1px 1px 2px #fff;
	-webkit-box-shadow: -0px -1px 1px #59656C, 
						-1px -1px 2px #59656C, 
						0px 1px 1px #fff, 
						1px 1px 2px #fff;
	-o-box-shadow: 		-0px -1px 1px #59656C, 
						-1px -1px 2px #59656C, 
						0px 1px 1px #fff, 
						1px 1px 2px #fff;
	box-shadow: 		-0px -1px 1px #59656C, 
						-1px -1px 2px #59656C, 
						0px 1px 1px #fff, 
						1px 1px 2px #fff;
}
#nav_top li.selected span{
	display:block;
	position:absolute;
	width:100%;
	height:100%;
	top:0;
	left:0;
	-moz-box-shadow: 0px 1px 2px #fff;
	-webkit-box-shadow: 0px 1px 2px #fff;
	-o-box-shadow: 0px 1px 2px #fff;
	box-shadow: 0px 1px 2px #fff;
}
#nav_top li a {
	display:block;
	float:left;
	padding-left:8px;
	padding-right:8px;
	width:auto;
	height:20px;
	position:relative;
	top:0px;
	left:0px;
}
#nav_top li a:hover {
	-moz-box-shadow: 	0px 0px 10px rgba(16,0,0,.15) inset,
						1px 1px 1px rgba(255,255,255,.75),
						-1px -1px 1px rgba(0,0,0, 0.25);
	-webkit-box-shadow: 0px 0px 10px rgba(16,0,0,.15) inset,
						1px 1px 2px rgba(255,255,255,.75),
						-1px -1px 2px rgba(0,0,0, 0.25);
	-o-box-shadow: 		0px 0px 10px rgba(16,0,0,.15) inset,
						1px 1px 1px rgba(255,255,255,.75),
						-1px -1px 1px rgba(0,0,0, 0.25);
	
	box-shadow: 		0px 0px 5px rgba(16,0,0,.15) inset,
						1px 1px 1px rgba(255,255,255,.75),
						-1px -1px 1px rgba(0,0,0, 0.25);
	top:1px;
	left:0px;
	text-decoration:none;
}
#nav_top li.selected a:hover, #nav_top li.nav-selected a:hover, #nav_top li.nav-path-selected a:hover{
	top:0px;
	text-shadow: -0px -1px 1px #59656C,  0px 1px 1px #fff;
	text-shadow: 0px 1px 1px #59656C,  -0px -1px 1px #fff;
	background-color:rgba(54, 71, 104, 0.85); /*#364768;*/
	-moz-box-shadow: 	-0px -1px 1px #59656C, 
						-1px -1px 2px #59656C, 
						0px 1px 1px #fff, 
						1px 1px 2px #fff;
	-webkit-box-shadow: -0px -1px 1px #59656C, 
						-1px -1px 2px #59656C, 
						0px 1px 1px #fff, 
						1px 1px 2px #fff;
	-o-box-shadow: 		-0px -1px 1px #59656C, 
						-1px -1px 2px #59656C, 
						0px 1px 1px #fff, 
						1px 1px 2px #fff;
	box-shadow: 		-0px -1px 1px #59656C, 
						-1px -1px 2px #59656C, 
						0px 1px 1px #fff, 
						1px 1px 2px #fff;

	/*-moz-box-shadow: 	0px 1px 1px #59656C, 
						1px 1px 2px #59656C, 
						0px 1px 1px #fff, 
						-1px -1px 2px #fff, 
						1px 1px 2px rgba(255,255,255,.25) inset;
	-webkit-box-shadow: 0px 1px 1px #59656C, 
						1px 1px 2px #59656C, 
						0px 1px 1px #fff, 
						-1px -1px 2px #fff, 
						1px 1px 2px rgba(255,255,255,.25) inset;
	-o-box-shadow: 		0px 1px 1px #59656C, 
						1px 1px 2px #59656C, 
						0px 1px 1px #fff, 
						-1px -1px 2px #fff, 
						1px 1px 2px rgba(255,255,255,.25) inset;
	box-shadow: 		0px 1px 1px #59656C, 
						1px 1px 2px #59656C, 
						0px 1px 1px #fff, 
						-1px -1px 2px #fff, 
						1px 1px 2px rgba(255,255,255,.25) inset;*/
	
}
#content {
	color:#3b454e;
}

#content hr {
	border-top-width: thin;
	border-right-width: thin;
	border-bottom-width: thin;
	border-left-width: thin;
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-color: #8D6E34;
	border-right-color: #fff;
	border-bottom-color: #fff;
	border-left-color: #8D6E34;
	margin-top: 12px;
	margin-bottom: 12px;
	clear: both;
	height: 0px;
}
/* ::: TITULO PAGINA interior */
#pagename {
	/*height:120px;*/
	overflow:hidden;
	margin:0px;
	padding:0px;
}
#pagename h1 {
	text-shadow: 	1px 1px 1px #fff, 
					-1px -1px 1px #000;
	opacity: 0.3;
	color:#7F4109;
	/*filter: progid:DXImageTransform.Microsoft.Shadow(color='#ffffff', Direction=135, Strength=1);
    color: rgba(127, 65, 9, 0.3);*/
    
	font-size:100px;
	height:105px;
	line-height:100%;
	text-transform:uppercase;
	margin:0px;
	padding:0px;
}

/* ::: columna izquierda */
#col_left {
	width: 20%;
	float: left;
}
#col_left_in {
	padding-top: 0px;
	padding-right: 12px;
	padding-bottom: 12px;
	padding-left: 12px;
}
#col_left img {
	float: left;
	margin-right: 12px;
}
#col_left .nota {
	margin-bottom:12px;
	-moz-border-radius: 4px;
	border-radius: 4px;

}
#col_left ul.nav {
	clear:both;
	}
#col_left ul.nav li {
	-moz-box-shadow: 	-1px -1px 2px rgba(255,255,255,0.5), 
						1px 1px 2px rgba(0,0,0,0.5);
	-webkit-box-shadow: -1px -1px 2px rgba(255,255,255,0.5), 
						1px 1px 2px rgba(0,0,0,0.5);
	-o-box-shadow: 		-1px -1px 2px rgba(255,255,255,0.5), 
						1px 1px 2px rgba(0,0,0,0.5);
	box-shadow: 		-1px -1px 2px rgba(255,255,255,0.5), 
						1px 1px 2px rgba(0,0,0,0.5);

	list-style:none;
	margin-bottom:3px;
	padding-left:12px;
}
#col_left ul.nav li.nav-selected {
	-moz-box-shadow: 	-1px -1px 2px rgba(255,255,255,0.5) inset, 1px 1px 2px rgba(0,0,0,0.5) inset;
	-webkit-box-shadow: -1px -1px 2px rgba(255,255,255,0.5) inset, 1px 1px 2px rgba(0,0,0,0.5) inset;
	-o-box-shadow: -1px -1px 2px rgba(255,255,255,0.5) inset, 1px 1px 2px rgba(0,0,0,0.5) inset;
	box-shadow: 		-1px -1px 2px rgba(255,255,255,0.5) inset, 1px 1px 2px rgba(0,0,0,0.5) inset;

}
/* SEARCH BLOCK */
input.ccm-search-block-text {
	width: 169px;
}
/* ::: cuerpo central */
#col_center {
	float: left;
	width: 100%;
}


/* :banner_top */
#banner_top {
	height: 165px;
	position: relative;
}
#banner_top .in {
	position:relative;
	z-index:2;
}
#banner_top .in_editmode {
	position:relative;
	z-index:auto;
}
#banner_top .shadow {
	background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/banner_top_shadow.png);
	height: 164px;
	width: 773px;
	display: block;
	position: absolute;
	left: 0px;
	top: 25px;
}
#banner_top .content {
	height: 155px;
	width: 759px;
	background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/banner_top_bkg.png);
	position: relative;
	left: 0px;
	top: 0px;
}
#banner_top .content .in {
	padding:2px;
}
#bannerabierto {
	margin-right:12px;
	float:left;
}
#banner_top ul li {
	font-size: 14px;
	line-height: 40px;
	border-top-width: thin;
	border-top-style: dotted;
	list-style-type: none;
	/*list-style-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/Banner_top_bullets.png);*/
	padding-left: 510px;
	list-style-position: inside;
	/*background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/Banner_top_bullets.png);
	background-repeat: no-repeat;
	background-position: 515px center;*/
	margin-left:0;
}
#banner_top ul li.first {
	border: none;
}
#banner_top ul li.selected {
	list-style-image:url(http://www.fengshui-argentina.com/themes/ehfs3/images/bullet_letterpress_dotSelect.png);
	/*
	background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/Banner_top_bullets_selected.png);
	background-repeat: no-repeat;
	background-position: 508px center;*/
}
#banner_top img {
	position:absolute;
	top:2px;
	left:2px;
	width:505px;
	height:120px;
	display:block;
	z-index:1;
}
#banner_top img.active {
	z-index:3;
	display:block
}
/* : TABLAS : */
#content table {
	width: auto;
	font-size:smaller;
	margin: 0 auto;
}

#content th, #content td {
	background-color: rgba(255,255,255,0.25);
	padding-left:6px;
	padding-right:6px;
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-width: 1px;
	border-right-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-top-color: rgba(0,0,0,0.25);
	border-right-color: rgba(255,255,255,0.25);
	border-bottom-color: rgba(255,255,255,0.25);
	border-left-color: rgba(0,0,0,0.25);
	
	-webkit-box-shadow: 	0px 0px 25px rgba(64,0,0,0.1) inset;
	-o-box-shadow: 			0px 0px 25px rgba(64,0,0,0.1) inset;
	-moz-box-shadow: 		0px 0px 25px rgba(64,0,0,0.1) inset;
	box-shadow: 			0px 0px 25px rgba(64,0,0,0.1) inset;
	}



/* :columnas centrales */
#colcenter_header .in {
	padding-left:12px;
	padding-right:12px;
}

#col_center li {
	margin-left:2em;
}
#col_center .columna {
	width:50%;
	float:left;
}
#col_center .columna .in {
	padding: 12px;
}
/* --- nota: */
#col_center .nota {
	color: #364768; 
	background-color: #f4f4f4;
	padding: 1em;
	border-top-width: thin;
	border-right-width: thin;
	border-bottom-width: thin;
	border-left-width: thin;
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-color: #8D6E34;
	border-right-color: #fff;
	border-bottom-color: #fff;
	border-left-color: #8D6E34;
	-moz-border-radius: 2px;
	border-radius: 2px;
	background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/rough-beige-paper-texture_2_EQ.jpg);
	margin-bottom:12px;
	
	-webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.15) inset,
						0px 0px 10px rgba(0,0,0,0.25) inset;
	-moz-box-shadow: 	0px 0px 3px rgba(0,0,0,0.15) inset,
						0px 0px 10px rgba(0,0,0,0.25) inset;
	-o-box-shadow: 		0px 0px 3px rgba(0,0,0,0.15) inset,
						0px 0px 10px rgba(0,0,0,0.25) inset;	
	
	box-shadow: 		0px 0px 3px rgba(0,0,0,0.15) inset,
						0px 0px 10px rgba(0,0,0,0.25) inset;
}

#col_center .nota ul {
	padding-left:1em;
}
#col_center .nota img {
	width: 100px;
	height: auto;
	border: thick solid #FFF;
	-moz-box-shadow: 	1px 1px 3px #A99CA8;
	-webkit-box-shadow: 1px 1px 3px #A99CA8;
	-o-box-shadow: 1px 1px 3px #A99CA8;
	box-shadow: 		1px 1px 3px #A99CA8;
	color:#A99CA8;
	float: left;
	margin-right: 24px;
	margin-bottom: 12px;
	margin-top: 3px;
}
/* --- curso */
#col_center .curso {
	color: #364768; 
	background-color: #f4f4f4;
	padding: 1em;
	border-top-width: thin;
	border-right-width: thin;
	border-bottom-width: thin;
	border-left-width: thin;
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-color: #8D6E34;
	border-right-color: #fff;
	border-bottom-color: #fff;
	border-left-color: #8D6E34;
	-moz-border-radius: 2px;
	border-radius: 2px;
	background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/rough-beige-paper-texture_2_EQ.jpg);
	margin-bottom:12px;

	-webkit-box-shadow: 		0px 0px 3px rgba(0,0,0,0.15) inset,
						0px 0px 10px rgba(0,0,0,0.25) inset;
	-moz-box-shadow: 		0px 0px 3px rgba(0,0,0,0.15) inset,
						0px 0px 10px rgba(0,0,0,0.25) inset;
	-o-box-shadow: 		0px 0px 3px rgba(0,0,0,0.15) inset,
						0px 0px 10px rgba(0,0,0,0.25) inset;

	box-shadow: 		0px 0px 3px rgba(0,0,0,0.15) inset,
						0px 0px 10px rgba(0,0,0,0.25) inset;
}
/* --- elevado: */

#col_center .elevado, .elevado {
	clear:both;
	/* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/madera2.jpg); */
	/* background-position: left top;

	border-top-width: thin;
	border-right-width: thin;
	border-bottom-width: thin;
	border-left-width: thin;
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-color: #FFFFFF;
	border-right-color: #8D6E34;
	border-bottom-color: #8D6E34;
	border-left-color: #FFF;
	-moz-border-radius: 2px;
	border-radius: 2px;
	margin-bottom: 12px;
	
	box-shadow: 0px 1px 0px #8D6E34,
				0px 2px 0px #8a6b33,
				0px 3px 0px #795d2c,
				0px 4px 0px #6b5026,
				0px 5px 0px #6b5026,
				0px 6px 0px #4f391a,
				0px 7px 0px #4f391a,
				
				0px 8px 1px rgba(61,42,19,0.8),
				1px 9px 2px rgba(61,42,19,0.6),
				3px 11px 3px rgba(61,42,19,0.4),
				6px 14px 4px rgba(61,42,19,0.3),
				10px 18px 5px rgba(61,42,19,0.2),
				15px 13px 8px rgba(61,42,19,0.1); */
}
#col_center .elevado .in, .elevado .in {
	padding: 1em;
	}
#col_center .elevado h1, .elevado h1, #col_center .elevado h2, .elevado h2{
	text-transform:uppercase;
	letter-spacing:.1em;
	font-size:24px;
	/*color:rgba(0,0,0,0);*/
	text-shadow:
	 	1px 1px 1px rgba(255,255,255,.5), 
		0px 1px 1px rgba(255,255,255,.5),
		1px 0px 1px rgba(255,255,255,.5),
		-1px -1px 1px rgba(0,0,0,.5),
		-0px -1px 1px rgba(0,0,0,.5),
		-1px -0px 1px rgba(0,0,0,.5);
	/*opacity: 0.3;
	color:#7F4109;*/
		
}
#col_center ul {
	padding-left:1em;
}
#col_center .nota img{
	width: 100px;
	height: auto;
	border: thick solid #FFF;
	-moz-box-shadow: 	1px 1px 3px #A99CA8;
	-webkit-box-shadow: 1px 1px 3px #A99CA8;
	-o-box-shadow: 		1px 1px 3px #A99CA8;
	box-shadow: 		1px 1px 3px #A99CA8;
	color:#A99CA8;
	float: left;
	margin-right: 24px;
	margin-bottom: 12px;
	margin-top: 3px;
}
/* hundido */
#col_center .hundido {
	padding: 1em;
	margin-bottom:12px;
	border-top-width: thin;
	border-right-width: thin;
	border-bottom-width: thin;
	border-left-width: thin;
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-color: #8D6E34;/*sombra*/
	border-right-color:#EAD6BB;/*luz*/
	border-bottom-color:#EAD6BB;/*luz*/
	border-left-color: #8D6E34;/*sombra*/
	-moz-border-radius: 2px;
	border-radius: 2px;

	box-shadow: 		0px 0px 10px rgba(16,0,0,.25) inset;
}
/* hundido extra */
#col_center .xhundido, .xhundido {
	clear: both;
	padding-left: 12px;
	padding-top: 12px;
	padding-right:6px;
	padding-bottom:6px;
	/* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/madera2.jpg); */
	background-position: left top;
/* 	border-top-width: thin;
	border-right-width: thin;
	border-bottom-width: thin;
	border-left-width: thin;
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-color: #8D6E34;
	border-right-color: #FFF;
	border-bottom-color: #FFF;
	border-left-color: #8D6E34; */
	-moz-border-radius: 2px;
	border-radius: 2px;
	margin-bottom: 12px;
	/* box-shadow: 0px 1px 0px #8D6E34 inset,
				0px 2px 0px #8a6b33 inset,
				0px 3px 0px #795d2c inset,
				0px 4px 0px #6b5026 inset,
				0px 5px 0px #6b5026 inset,
				0px 6px 0px #4f391a inset,
				0px 7px 0px #4f391a inset,
				
				0px 8px 1px rgba(61,42,19,0.8) inset,
				1px 9px 2px rgba(61,42,19,0.6) inset,
				3px 11px 3px rgba(61,42,19,0.4) inset,
				6px 14px 4px rgba(61,42,19,0.3) inset,
				10px 18px 5px rgba(61,42,19,0.2) inset,
				15px 13px 8px rgba(61,42,19,0.1) inset; */
}

/* paper suelto */
.paper {
	height: auto;
	/* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/papertile/tile.jpg); */
	background-color: #fafafa;
	position: relative;
	/* margin-bottom:12px; */
}

.paper .in {
	padding: 12px;

}
.paper .tl {
	position: absolute;
	top: -3px;
	left: -3px;
	/* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/papertile/TL.png); */
	display:block;
	width:3px;
	height:3px;
}
.paper .t {
	position: absolute;
	top: -3px; 
	left: 0;
	/* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/papertile/T.png); */
	display:block;
	width:100%;
	height:3px;
}
.paper .tr {
	position: absolute;
	top: -3px; 
	right: -6px;
	/* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/papertile/TR.png); */
	display:block;
	width:6px;
	height:3px;
}
.paper .r {
	position: absolute;
	top: 0px; 
	right: -6px;
	/* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/papertile/R.png); */
	display:block;
	width:6px;
	height:100%;
}
.paper .br {
	position: absolute;
	bottom: -6px; 
	right: -6px;
	/* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/papertile/BR.png); */
	display:block;
	width:6px;
	height:6px;
}
.paper .b {
	position: absolute;
	bottom: -6px; 
	left: 0;
	/* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/papertile/B.png); */
	display:block;
	width:100%;
	height:6px;
}
.paper .bl {
	position: absolute;
	bottom: -6px; 
	left: -3px;
	/* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/papertile/BL.png); */
	display:block;
	width:3px;
	height:6px;
}
.paper .l {
	position: absolute;
	top: 0px; 
	left: -3px;
	/* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/papertile/L.png); */
	display:block;
	width:3px;
	height:100%;
}
.paper /* h1, .paper h2, .paper h3, .paper h4, .paper h5, .paper h6, .paper p,*/  {
	text-shadow: -0px -1px 1px rgba(89,101,108, 0.5),  0px 1px 1px rgba(255,255,255,0.75); /* #59656C*/
}
div.paper hr {
	border-top:	1px solid #333;
	border-bottom:1px solid #fff;
	-webkit-box-shadow: -1px -1px 1px rgba(0,0,0,0.25), 1px 1px 1px rgba(255,255,255,0.5);
	-moz-box-shadow: 	-1px -1px 1px rgba(0,0,0,0.25), 1px 1px 1px rgba(255,255,255,0.5);
	-o-box-shadow: 		-1px -1px 1px rgba(0,0,0,0.25), 1px 1px 1px rgba(255,255,255,0.5);
	box-shadow: 		-1px -1px 1px rgba(0,0,0,0.25), 1px 1px 1px rgba(255,255,255,0.5);
	opacity: 0.5;
}
/* end paper suelto */

/* ::: pie de pagina */
#footer {
	background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/_footer.png);
	height: 50px;
	background-repeat: no-repeat;
	text-shadow: -0px -1px 1px #59656C,  0px 1px 1px #fff;
	font-family: 'Montserrat', sans-serif;
	}
#footer .in {
	padding-top: 12px;
	padding-left: 24px;
}
/* ++++++++++++++++++++ CALCULADORA ESTRELLAS +++++++++++++++++ */
#estrellas_resultado {
	font-size:2em;
}
#estrellas_resultado h5 {
	font-size:18px;
	color:rgba(0,0,0,.5);
}
#estrellas_resultado td{
	width:100px;
	height:100px;
	background: none;
	border: none;
	text-align:center;
	vertical-align:central;
	-moz-box-shadow: 	0px 0px 10px rgba(16,0,0,.15) inset,
						1px 1px 1px rgba(255,255,255,.75),
						-1px -1px 1px rgba(0,0,0, 0.25);
	-webkit-box-shadow: 0px 0px 10px rgba(16,0,0,.15) inset,
						1px 1px 1px rgba(255,255,255,.75),
						-1px -1px 1px rgba(0,0,0, 0.25);
	-o-box-shadow: 		0px 0px 10px rgba(16,0,0,.15) inset,
						1px 1px 1px rgba(255,255,255,.75),
						-1px -1px 1px rgba(0,0,0, 0.25);
	
	box-shadow: 		0px 0px 5px rgba(16,0,0,.15) inset,
						1px 1px 1px rgba(255,255,255,.75),
						-1px -1px 1px rgba(0,0,0, 0.25);
	border-bottom-width: 2px;
	border-bottom-color: #FFF;
	border-right-width: 2px;
	border-right-color: #FFF;
}

#estrellas_resultado h1, #estrellas_resultado h2, #estrellas_resultado h3, #estrellas_resultado h4, #estrellas_resultado h5, #estrellas_resultado h6 {
	/*float:left;*/
	display:inline;
	line-height:75px;
	width:30%;
	
	font-weight:700;

	text-align:center;
	/*
	-moz-box-shadow: 	0px 0px 10px rgba(16,0,0,.15) inset,
						1px 1px 1px rgba(255,255,255,.75),
						-1px -1px 1px rgba(0,0,0, 0.25);
	-webkit-box-shadow: 0px 0px 10px rgba(16,0,0,.15) inset,
						1px 1px 1px rgba(255,255,255,.75),
						-1px -1px 1px rgba(0,0,0, 0.25);
	-o-box-shadow: 		0px 0px 10px rgba(16,0,0,.15) inset,
						1px 1px 1px rgba(255,255,255,.75),
						-1px -1px 1px rgba(0,0,0, 0.25);
	
	box-shadow: 		0px 0px 5px rgba(16,0,0,.15) inset,
						1px 1px 1px rgba(255,255,255,.75),
						-1px -1px 1px rgba(0,0,0, 0.25);
						*/
}
td#laptext {
	height:2em
}
input#jaar {
	text-align:center;
	width: 70px;
}
/* -------- FORM 4 PILARES ----------- */
#calculo4pilares {
	line-height:120%;
}
#calculo4pilares table {
	width:100%;
}

#comment1, #comment2 {
	width:100%;
}

/* 
----- CLASSES ------ */


/* ----------------- */
 .tooltip {
    display:none;
    background: rgba(0,0,0,0.5);
    font-size:12px;
    height:auto;
    width:200px;
    padding:25px;
    color:#eee;
	text-align:left;
}

.boton-resultados{
	margin-top: 12px;
}

.col-sm{
	padding-left: 0px;
}

@media only screen and (min-width: 768px) {
	.boton-resultados{
		display: none;
	}
}

@media only screen and (max-width: 768px) {
	.col-sm{
		padding-left: 0px;
		padding-right: 0px;
	}

	.botones-menu{
		margin-left: 0px !important;
		margin-top: 10px;
	}
}

/* FIN MAIN.CSS */

/* ---------------------------- */

/* STYLES.CSS */
  
/* ==================================================================== */
/* ====================== ESTRELLAS VOLANTES ========================== */
/* ==================================================================== */
.tablero {
	position:relative;
}
#estrellasvolantes_wrapper {
	position:relative;
	background: #fafafa;
	border-radius:6px;
	width:100%;
	margin-top:12px;
	float:left;
	/* background:url(http://www.fengshui-argentina.com/themes/ehfs3/images/madera2.jpg); */
	z-index:2;
	box-shadow: 1px 1px 2px rgba(0,0,0,1)
				, 1px 1px 0px rgba(255,255,255,.5) inset;
}
.resultadoticket {
	position:relative;
	width:100%;
	/* margin-left:1%; */
	float:left;
	z-index:1;
	margin-top:12px;
	border-radius: 6px;
	box-shadow: 1px 1px 2px rgba(0,0,0,1)
				, 1px 1px 0px rgba(255,255,255,.5) inset;
	text-align: center;
}
.resultadoticket h3 {
	text-transform:uppercase;
	color: rgba(0,0,0,0);
	font-size:24px;
	font-weight:400;
	
}
/* ==== AGUJA ==== */
#estrellasvolantes .aguja {
	display:block;
	position:absolute;
	background: grey;
	width:95%;
	height: 2%;
	top: 49%;
	left: 2.5%;
	z-index:9;
}
.aguja_in {
	display:block;
	width:100%;
	height:100%;
	position: relative
}
.aguja_color {
	position:absolute;
	display:block;
	height:100%;
	width:50%;
	right:0;
}
#estrellasvolantes .aguja_center {
	display:block;
	position:absolute;
	background: darkred;
	width:8%;
	height: 8%;
	margin-top:46%;
	margin-bottom:46%;
	margin-left:46%;
	margin-right:46%;
	z-index:10;
	border-radius:100%;
	
}
.aguja .aguja_color {
		background: darkred;
}

#estrellasvolantes .aguja_shadow {
	display:block;
	position:absolute;
	width:95%;
	height: 2%;
	top: 52%;
	left: 4.5%;
	background: darkred;
	background: rgba(0,0,0,.25);
	z-index:8;
	box-shadow:
		0px 0px 5px rgba(0,0,0,.5);
	
}
/*=============================== */

.table-4pilares{
	width: 80% !important;
}

.guapersonal-txt{
	text-align: center;
	padding: 20px;
}

#content #estrellasvolantes table {
	/* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/madera2.jpg);
	background-position: center top; */
	margin: 0 auto;
}
#content #estrellasvolantes table td {
	text-align:center;
	padding:0;
	width:45px;
	height:45px;
	border:none 0px;
	background:none;
}
#content #estrellasvolantes table td.luopan {
	width:225px;
	height:225px;
	
	
}
.luopan {
	position:relative;
	height:95%;
	width:95%;
	margin:2.5%;
	background:url(../images/madera2.jpg) center center;
	border-radius: 2px;
	box-shadow:
		-1px -1px 1px rgba(0,0,0,.5)
		,1px 1px 1px rgba(255,255,255,.5)
		,-1px -1px 1px rgba(0,0,0,.5) inset
		,1px 1px 1px rgba(255,255,255,.5) inset;
}
.luopan_in {
	background:url('../images/LuoPan_02_noring.png') center center;
	width:100%;
	height:100%;
}
/* ====== RADIO BACKLIT BUTTON ==== */
.btn_backlit {
	display:inline-block;
	width:44px;
	height:35px;
	position:relative;
}
.btn_backlit label {
	cursor:pointer;
	display:block;
	position:absolute;
	top:0;
	left:3px;
	width:35px;
	height:35px;
	padding: 3px;
	
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	text-transform:uppercase;
	text-align:center;
	line-height:30px;
	letter-spacing:.2em;
	font-weight:400;

	color: inherit; /*rgba(188,158,128, .5);*/

	text-shadow:
	/* bevel */
		-1px -1px 1px rgba(0,0,0,.5)
		, 0px -1px 1px rgba(0,0,0,.5)
		, -1px 0px 1px rgba(0,0,0,.5)
		, 1px 1px 1px rgba(255,255,255,.5)
		, 0px 1px 1px rgba(255,255,255,.5)
		, 1px 0px 1px rgba(255,255,255,.5)
		
		/*0 0 1px rgba(255,255,255,.1),
		0 0 2px rgba(255,255,255,.1),
		0 0 4px rgba(255,255,255,.5)*/
		;
		
	background:url(../images/madera2.jpg); /*#99C2D6; #a30000;*/
	
	border-radius: 5px;
	
	box-shadow:
		/* top */
		-1px -1px 1px rgba(64,32,0,.5) inset,
		0 0 15px rgba(64,32,0,.5) inset,
		0 0 5px rgba(64,32,0,.25) inset,
		

		/* cuerpo 
				0px 1px 2px rgba(0,0,0,.2),
				0px 1px 0px #605A47,
				0px 2px 0px #4D4839,
				0px 3px 0px #3A362B,
				0px 4px 0px #26241C,*/
				0px 1px 2px rgba(0,0,0,.2),
				0px 1px 0px #8D6E34,
				0px 2px 0px #8a6b33,
				0px 3px 0px #795d2c,
				0px 4px 0px #6b5026,

		/* dropshadow */
		0px 5px 1px rgba(61,42,19,0.8),
		1px 6px 2px rgba(61,42,19,0.6),
		3px 8px 3px rgba(61,42,19,0.4),
		6px 11px 4px rgba(61,42,19,0.3);

		/* sobra radial 
		0 6px 1px	rgba(0,0,0,1),
		0 6px 3px	rgba(0,0,0,1),
		0 6px 3px	rgba(0,0,0,1),
		0 6px 6px	rgba(0,0,0,1),*/
		/* sombra proyectada 
		1px 7px 2px	rgba(0,0,0,.25),
		3px 9px 3px	rgba(0,0,0,.25),
		6px 12px 4px	rgba(0,0,0,.25)*/
		;


}
.btn_backlit input[type=radio] {
	background: none;
	position: absolute;
	left: 0;
	margin: 0;
	width: 35px;
	height: 30px;
	/*hide the radio button*/
	filter:alpha(opacity=0);
	-moz-opacity:0;
	-khtml-opacity: 0;
	opacity: 0;
    cursor: pointer;
	
}
 .label-checked{
	background: rgba(255,255,200,.5) !important;/*#E6F0F5;*/
	color: grey !important; /*rgba(255,255,255,.75);*/
	top:2px !important;
	text-shadow: none !important; 
	/*color: rgba(255,200,178, .75); rgba(255,128,32, .5);*/
	
	text-shadow:
	/* bevel */
		-1px -1px 0px rgba(255,255,200,.5)
		, 0px -1px 0px rgba(255,255,200,.5)
		, -1px 0px 0px rgba(255,255,200,.5)
		, 1px 1px 0px rgba(255,255,200,.5)
		, 0px 1px 0px rgba(255,255,200,.5)
		, 1px 0px 0px rgba(255,255,200,.5)
		/*
		0 0 1px rgba(255,255,255,1),
		0 0 2px rgba(255,255,255,1),
		0 0 4px rgba(255,255,255,.5)
	
	
		0 0 4px rgba(255,128,0,.25),
		0 0 1px rgba(255,200,178,1),
		0 0 2px rgba(255,64,0,.1),
		0 0 4px rgba(255,0,0,.1);
		*/
	!important;
	
	box-shadow:
		/* top */
		1px 1px 1px rgba(200,180,120,1) inset,
		-1px -1px 1px rgba(200,180,120,1) inset,
		0 0 15px rgba(200,180,120,.5) inset,
		0 0 5px rgba(200,180,120,.25) inset,

		/* cuerpo */
		0 1px 2px rgba(200,180,120,1),
		0 1px 4px rgba(200,180,120,1),
		0 1px 1px #9A8F72,
		0 2px 1px #9A8F72,
		0 3px 1px #9A8F72, /*920000,*/
		0 4px 1px #9A8F72,
		/* sobra radial */
		0 4px 1px	rgba(64,32,0,.5),
		0 4px 3px	rgba(64,32,0,.5),
		0 4px 3px	rgba(64,32,0,.5),
		0 4px 6px	rgba(64,32,0,.5),
		/* sombra proyectada */
		1px 5px 2px	rgba(0,0,0,.25),
		3px 7px 3px	rgba(0,0,0,.25),
		/* brillo residual */
		0px 4px 5px rgba(255,255,255,1),
		0px 4px 15px	rgba(255,255,255,1),
		0px 4px 25px	rgba(255,255,255,1)
		
		!important;
}
/* ================= */

.chapacobre {
	color: rgb(76, 38, 19);
	text-transform:uppercase;
	text-align:center;
	border-radius: 1em;
	margin-left: 0em;
	background-color: rgba(204,102,51,1);
	padding-right: 0em;
	padding-left: 0em;
	text-shadow: 
		0 1px 1px rgba(255, 128, 64, 1),
		1px 0 1px rgba(255, 128, 64, 1),
		0 -1px 1px rgba(76, 38, 19, 0.5),
		-1px 0 1px rgba(76, 38, 19, 0.5)
	;
	
	box-shadow:
		-1px -1px 1px rgba(245, 197, 120, 1),
		1px 1px 2px rgba(0,0,0,1),
		-1em -.2em 10px rgba(0,0,0,.15) inset,
		1em .2em 10px rgba(255,255,255,.15) inset; 
}
.chapacobre:before {
	content:"•";
	position:relative;
	top:-0em;
	left:-.2em
}
.chapacobre:after {
	content:"•" ;
	position:relative;
	top:-00em;
	left:.2em
}
.alpie {
	display:block;
	position:relative;
	float:left;
	width:95%;
	height:1.5em;
	margin-top:3px;
	top:50px;
	left:0px;
	font-size:10px;
	line-height:1.5em;
}
/* ================= */
.retro___display{
	display:inline-block;
	margin-right:12px;
	width:auto;
	height:auto;
	background: darkred;
	text-align:center;
	line-height:1.5em;
	padding-left:.5em;
	padding-right:.5em;
	font-size:40px;
	font-weight:700;
	letter-spacing:.1em;
	color:rgba(255,32,0, .15);
	border-radius:10px;	
	box-shadow: 
		0 0 25px rgba(0,0,0,1) inset,
		0 0 35px rgba(0,0,0,.5) inset,
		-1px -1px 1px rgba(0,0,0,1),
		1px 1px 1px rgba(255,255,255,.5);
	text-shadow:
		0 0 4px rgba(255,128,0,1),
		0 0 1px rgba(255,32,0,1),
		0 0 2px rgba(255,0,0,1),
		0 0 4px rgba(255,0,0,.5);
}

/* === SCREWS === */
.screw:before {
	content: '\\';
}
.screw.phillips:before {
	content: '+';
}
.screw {
	font-size:12px;
	display: inline-block;
	width:1em;
	height:1em;
	overflow:hidden;
	font-family: Arial, sans-serif;
	padding-left:.2ex;
	text-align:center;
	line-height:1em;
	background:darkgrey;
	color:darkgrey;	
	
	border-radius:1em;
	text-shadow:
		0px -1px 1px rgba(0,0,0,.5),
		-1px 0px 1px rgba(0,0,0,.5),
		-1px -1px 1px rgba(0,0,0,.5),
		0px 1px 1px rgba(255,255,255,.25),
		1px 0px 1px rgba(255,255,255,.25),
		1px 1px 1px rgba(255,255,255,.25);
	box-shadow:
		1px 1px 1px rgba(0,0,0,.5),
		2px 2px 3px rgba(0,0,0,.5),
		0 0 2px rgba(0,0,0,1),
		0 0 5px rgba(0,0,0,1),
		-.1em -.1em .2em rgba(0,0,0,.3) inset,
		-.25em -.25em .5em rgba(0,0,0,.3) inset,
		-.5em -.5em .5em rgba(0,0,0,.2) inset;
	/*	
	transform:rotate(10deg);
	-ms-transform:rotate(10deg); 
	-moz-transform:rotate(10deg); 
	-webkit-transform:rotate(10deg); 
	-o-transform:rotate(10deg); */

		
}
.screw.copper {
	color: rgba(174,90,32,1);
	background-color: rgba(204,102,51,1);/*rgba(174,90,32,1);*/
}
.screw.brass {
	color: rgba(170,120,32,1);
	background-color: rgba(170,120,32,1);/*rgba(174,90,32,1);*/
}
.stud {
	color:#333;
	display:block;
	width:1ex;
	height:1ex;
	line-height:1ex;
	text-shadow:
		0px -1px 1px rgba(0,0,0,.5),
		-1px 0px 1px rgba(0,0,0,.5),
		-1px -1px 1px rgba(0,0,0,.5),
		
		0px 1px 1px rgba(255,255,255,.25),
		1px 0px 1px rgba(255,255,255,.25),
		1px 1px 1px rgba(255,255,255,.25),
		
		0px 0px 3px rgba(0,0,0,1)
		,0px 0px 6px rgba(0,0,0,1)
		;
}
.stud:before {
	content:'•'
}
.b_l, .t_l, .t_r, .b_r {
	position:absolute;
	display:block;
	margin:3px;
}
.b_l { bottom:0; left:0; }
.t_l { top:0; left:0 }
.t_r { top:0; right:0 }
.b_r { bottom:0; right:0 }


/* 
========================================== 
    CUATRO PILARES:
==========================================	
*/

#calculo4pilares_wrapper .elevado {
	margin-left:0;
}
#calculo4pilares_wrapper .col1 {
	/* width:100%; */
	position:relative;
	background: #fafafa;
	border-radius:6px;
	width:100%;
	margin-top:12px;
	float:left;
	/* background:url(http://www.fengshui-argentina.com/themes/ehfs3/images/madera2.jpg); */
	z-index:2;
	box-shadow: 1px 1px 2px rgba(0,0,0,1)
				, 1px 1px 0px rgba(255,255,255,.5) inset;
	/* float:left; */
}

/*
====================
KUA NUMBER
*/
td.exito {
	background: rgba(128,0,0,.5);
	color: darkgreen;
}
td.exito:before {
	content:" + ";
	background: darkgreen;
	color: rgba(255,255,255,.5);
}
td.peligro {
	background: rgba(0,128,0,.5);
	color: darkred;
}
td.peligro:before {
	content:" – ";
	background: darkred;
	color: rgba(255,255,255,.5);
}
td.exito:before , td.peligro:before {
	text-align:center;
	display:block;
	font-size: 24px;
	width:1em
	}
#kua_resultado td {
	width:186px;
	height:186px;
}
.rosavientos {
	background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/RosaDeLosVientos.png);
	background-repeat: no-repeat;
	background-position: center center;
}
/* SELECTBOX PLUGIN */
div.selectbox-wrapper ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	position:absolute;
}
div.selectbox-wrapper ul li.selected { 
  background-color: rgba(255,255,255,.25);
  
}
div.selectbox-wrapper ul li.current { 
  background-color: rgba(255,255,255,.5);
}
div.selectbox-wrapper ul li {
  list-style-type:none;
  display:block;
  height:1em;
  margin:0;
  margin-bottom:0px;
  padding-top:6px;
  padding-bottom:6px;
  padding-left:12px;
  padding-right:12px;
  cursor:pointer;
  /* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/madera2.jpg); */
  
	text-shadow:
	/* bevel */
		-1px -1px 1px rgba(0,0,0,.25)
		, 0px -1px 1px rgba(0,0,0,.25)
		, -1px 0px 1px rgba(0,0,0,.25)
		, 1px 1px 1px rgba(255,255,255,.25)
		, 0px 1px 1px rgba(255,255,255,.25)
		, 1px 0px 1px rgba(255,255,255,.25)
  ;
  box-shadow:
  -1px -1px 1px rgba(0,0,0,.5) inset
  , 1px 1px 1px rgba(255,255,255,.5) inset
  ;
  padding: 10px;
}
input.selectbox {
	/* background-image: url(http://www.fengshui-argentina.com/themes/ehfs3/images/madera2_inputArrow.jpg); */
	background-repeat: repeat;
	background-position: right top;
	margin-bottom:-7px;
	
	text-shadow:
	/* bevel */
		-1px -1px 1px rgba(0,0,0,.25)
		, 0px -1px 1px rgba(0,0,0,.25)
		, -1px 0px 1px rgba(0,0,0,.25)
		, 1px 1px 1px rgba(255,255,255,.25)
		, 0px 1px 1px rgba(255,255,255,.25)
		, 1px 0px 1px rgba(255,255,255,.25)
  ;
/* 	border-top-width: thin;
	border-right-width: thin;
	border-bottom-width: thin;
	border-left-width: thin;
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-color: #FFFFFF;
	border-right-color: #8D6E34;
	border-bottom-color: #8D6E34;
	border-left-color: #FFF;
	-moz-border-radius: 2px;
	border-radius: 2px;
	margin-bottom: 12px;
	
	box-shadow: 0px 1px 0px #8D6E34,
				0px 2px 0px #8a6b33,
				0px 3px 0px #795d2c,
				0px 4px 0px #6b5026,
				0px 5px 0px #6b5026,
				0px 6px 0px #4f391a,
				0px 7px 0px #4f391a,
				
				0px 8px 1px rgba(61,42,19,0.8),
				1px 9px 2px rgba(61,42,19,0.6),
				3px 11px 3px rgba(61,42,19,0.4),
				6px 14px 4px rgba(61,42,19,0.3),
				10px 18px 5px rgba(61,42,19,0.2),
				15px 13px 8px rgba(61,42,19,0.1); */

}
/* end SELECTBOX PLUGIN */

